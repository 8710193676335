@import '../../Colors.less';

.end {
  .scores {
    margin: 6vw;
    max-height: 70vh;
    overflow: auto;
    .player {
      font-size: 3vh;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin: 1vh 0;
      .score {
        font-size: 5vh;
        margin-right: 2vw;
        width: 20vw;
        max-width: 10vh;
      }
      .crown {
        height: 5vh;
        width: 5vh;
        margin-left: 2vw;
        margin-bottom: 1vh;
      }
    }
  }
  .actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    .btnAction:extend(.myPolice all) {
      color: white;
      margin: 4vw;
    }
  }
}
