@import './Colors.less';

body,
html {
  margin: 0;
  padding: 0;
  background-color: @background;
}

.fullScreen:extend(.myPolice all) {
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  width: 100%;
}

.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: @secondary transparent;

  /* Chrome */
  &::-webkit-scrollbar {
    width: 5px;
    height: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: @secondary;
  }
}

.version:extend(.myPolice all) {
  position: fixed;
  bottom: 8px;
  left: 8px;
  color: gray;
  font-size: 12px;
}
