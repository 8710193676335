@import 'Fonts/fonts.less';

.myPolice {
  font-family: 'Audiowide';
}

/* Colors */
@primary: #32bea6;
@secondary: #651fff;
@error: #e40f0f;
@background: #32373e;
@scroll-bar-color: @primary;
