@import '../../Colors.less';

.wrapper {
  height: 30vh;
}

.drawerInstall {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.iconDrawer {
  width: 15vh;
  height: 15vh;
  padding: 3vw;
}

.textDrawer:extend(.myPolice all) {
  font-weight: bold;
  font-size: 3vh;
  padding: 2vw;
  text-align: center;
}

.buttons {
  padding-top: 2vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.btnDrawer {
  padding: 4vw;
}
