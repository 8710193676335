@import '../../Colors.less';

.home {
  .title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: @primary;
    margin-bottom: 3vh;
    h1 {
      font-size: 7vh;
    }
    .iconTitle {
      height: 10vh;
      width: 10vh;
      margin-right: 2vw;
    }
  }
  .switchAndButton {
    max-width: 300px;
    width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    .switchNSFW {
      .labelSwitch:extend(.myPolice all) {
        font-size: small;
      }
    }
  }
  .playButton:extend(.myPolice all) {
    color: white;
  }
  .randomSVG {
    height: 4vh;
    width: 4vh;
    min-height: 25px;
    min-width: 25px;
  }
}
