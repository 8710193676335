@import '../../Colors.less';

.playerList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 60vh;
  overflow: auto;
  margin-bottom: 2vh;
  .player {
    margin: 1vh;
  }
}
